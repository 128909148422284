/* 
  You can add global styles to this file, and also import other style files 
  https://material.angular.io/guide/theming

*/
/* @import '@angular/material/prebuilt-themes/indigo-pink.css'; */

:root{
  --sisre-bg-color: #dddddd;
  --sisre-altura-footer: 73px;
  --sisre-altura-header: 151px;
  --bs-primary-rgb: 19, 56, 126;
  --mdc-theme-primary: #13387e;
  --mdc-theme-primary-button-hover: #c8c8c8;
  --field-focused-border-color: #13387e;
   --field-focused-border-color-rgb: 255, 120, 0;
  --field-focused-border-width: 2px~;
  --espaco: 40px;
  --altura-minima: calc(var(--sisre-altura-footer) + var(--sisre-altura-header) + var(--espaco));
}

/*.mdc-button {
  color: #fff !important;
}

.mdc-button:hover {
  background-color: var(--mdc-theme-primary-button-hover) !important;
}

.mdc-button:focus {
  background-color: var(--field-focused-border-color) !important;
}*/

mat-label{
  font: 400 16px / 20px Roboto, sans-serif;
}

.table-requerimentos{
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.row-requerimentos-header{
  font-weight: 800;
  height: 65px;
  display: flex;
}

.row-requerimentos{
  height: 65px;
}



.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  //ocultar o elemento se não couber no espaço
  //display: inline-block;
  overflow: hidden;
}


/* .table-requerimentos tbody::after
{
  content: 'as';
  display: block;
  margin-bottom: 50px;
}  */
.table-requerimentos > thead tr {
  background: none;
  border: transparent;
}

.table-requerimentos tr {
  background-color: white;
}

.table-requerimentos tbody tr {
  border-width: 2px;
  border-radius: 5px;
}

.table-requerimentos tbody tr th:first-child, .table-requerimentos tbody tr td:first-child  {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table-requerimentos tbody tr th:last-child, .table-requerimentos tbody tr td:last-child  {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}



.container-min-height {
  min-height: calc(100vh - var(--altura-minima))
}
.container.login {
  height: 100vh !important;
}

.notificacoes {
  width: 250px;
}

.notificacoes-icon .mat-badge-medium.mat-badge-after .mat-badge-content{
  right: -10px;
}

.notificacoes-icon .dropdown-toggle::after {
  display: none;
}

html,
body {
  background: var(--sisre-bg-color) !important;
  margin: 0;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.ftco-section {
  padding: 15em 0;
}

.ftco-no-pt {
  padding-top: 0;
}

.ftco-no-pb {
  padding-bottom: 0;
}

.heading-section {
  font-size: 28px;
  color: #000;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.login-wrap a {
  text-decoration: none;
}

.login-wrap a:hover {
  color: #eb6526;
}

.login-wrap {
  position: relative;
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
}

.login-wrap h3 {
  font-weight: 700;
  font-size: 20px;
  color: var(--bs-primary);
}

.login-wrap .icon {
  width: 80px;
  height: 80px;
  background: var(--bs-primary);
  border-radius: 50%;
  font-size: 30px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.login-wrap .icon span {
  color: #fff;
}

.login-wrap .form-control.mat-mdc-form-field.form-control{
  height: auto;
  padding: 0;
}

.login-wrap .form-control .mat-mdc-form-field-bottom-align::before {
  content: "";
  display: none;
  height: 0px;
}

.login-wrap .form-control .mat-mdc-form-field-hint-wrapper, .form-control .mat-mdc-form-field-error-wrapper {
  position: relative;
  background-color: #fff;
  padding: 5px 0 0 0;
}

.login-wrap .mat-mdc-form-field-subscript-wrapper {
  background-color: white;
}

.login-wrap .form-control {
  height: 48px;
  background: rgba(0, 0, 0, 0.05);
  color: #000;
  font-size: 16px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0px solid transparent;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.07);
  border-color: transparent;
}

.checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "\f0c8";
  font-family: "FontAwesome";
  position: absolute;
  color: rgba(0, 0, 0, 0.1);
  font-size: 20px;
  margin-top: -4px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .checkmark:after {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

/* Show the checkmark when checked */
.checkbox-wrap input:checked~.checkmark:after {
  display: block;
  content: "\f14a";
  font-family: "FontAwesome";
  color: rgba(0, 0, 0, 0.2);
}

/* Style the checkmark/indicator */

/* .btn {
  cursor: pointer;
  border-radius: 40px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 15px;
} */

.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
}

.login-wrap .btn:disabled, .login-wrap .btn.disabled, .login-wrap fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: 0.9;
}

.form-group.btn-login{
  position: relative;
} 

.form-group .submit {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}


.nav-sisre .navbar-toggler{
  border: none;
  font-size: 14px;
}

@media (max-width: 768px) {
  .no-mobile {
    display: none !important;
  }
  .requerimento-id{
    min-width: 25%;
  }
}


@media (max-width: 767.98px) {
  .form-group .submit {
    top: 0;
  }
  .nav-sisre-submenu-duplo{
    display: none !important;
  }
}

@media (max-width: 425px) {
  .p-xs-1{
    padding: 0.1rem !important;
  }
}

@media (max-width: 320px) {
  .text-sisre{
    display: none;
  }
}


.list-group-item:hover, .dropdown-item:hover{
  background-color: var(--bs-primary);
  color: white;
}

.btn-action-requerimentos {
  width: 30px;
}

.btn-action-requerimentos.dropdown-toggle::after {
  content: none;
}

.img-thumbnail {
  padding: 0 !important;
  border: none !important;
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}