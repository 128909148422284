//@import url("https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css");

.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: '';
  z-index: 1
}

.steps .step .step-icon-wrap::before {
  left: 0
}

.steps .step .step-icon-wrap::after {
  right: 0
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5
}

.steps .step .step-icon button {
  color: var(--bs-primary);
  border-style: none;
  background-color: transparent; 
}

.steps .step.completed .step-icon button {
  color: #fff;
  border-style: none;
  background-color: transparent;
  filter: brightness(200%); 
}

.steps .step .step-icon button:disabled {
  color: rgba(54, 54, 54, 0.3);
  border-style: none;
  background-color: transparent;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
  display: none
}

.steps .step:last-child .step-icon-wrap::after {
  display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: var(--bs-primary);
}

.steps .step.completed .step-icon a {
  color: #fff
}
.steps .step.completed .step-icon{
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  color: #fff
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
      display: none
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
      display: none
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
      display: none
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
      display: none
  }
}

.bg-faded, .bg-secondary {
  background-color: #f5f5f5 !important;
}

.icone-step{
  height:60px; 
  width:60px;
  margin-bottom: 13px;
}

hr.classhr { 
  margin: auto;
  /*border-top: 3px double #0D6EFD;
  border-bottom: 3px double #0D6EFD;*/
  border: 1px solid #0D6EFD;
  width: 80%;
  opacity: 0.9;
}

.divhr{
  margin: 3rem 0;
}

.pt05rem{
  padding-top: 0.5rem;
}

.corTituloSobBarra{
  color: #606975;
}

.semBordaFundo{
  border:none;
  background-color:initial !important;
}